import React, { useState } from 'react';
import '../styles/ArtGrid.css'; // Import the CSS

const ArtGrid = ({ artworks }) => {
  const [hoveredImage, setHoveredImage] = useState(null); // Track which image is being hovered
  const [hoveredDescription, setHoveredDescription] = useState(null); // Track which description is being hovered
  const [hoverTimer, setHoverTimer] = useState(null); // Timer for hover delay

  const handleMouseMove = (e, index) => {
    if (hoveredImage === index) {
      const image = e.target;
      const rect = image.getBoundingClientRect(); // Get the image's position and size
      const x = ((e.clientX - rect.left) / rect.width) * 100; // Calculate x as a percentage
      const y = ((e.clientY - rect.top) / rect.height) * 100; // Calculate y as a percentage
      image.style.transformOrigin = `${x}% ${y}%`; // Set the transform origin dynamically
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredImage(index); // Set the hovered image index
  };

  const handleMouseLeave = (index) => {
    if (hoveredImage === index) {
      setHoveredImage(null); // Reset the hovered image index
    }
  };

  const handleMouseEnterDescription = (index) => {
    const timer = setTimeout(() => {
      setHoveredDescription(index);
    }, 250); // 0.5 seconds delay
    setHoverTimer(timer); // Save the timer
  };

  const handleMouseLeaveDescription = () => {
    // Clear the timer and reset the hovered description
    clearTimeout(hoverTimer); // Cancel the hover delay
    setHoverTimer(null);
    setHoveredDescription(null);
  };

  return (
    <div className="art-grid">
      {artworks.map((art, index) => (
        <div className="art-item" key={index}>
          {/* Image */}
          <div
            className="art-image-container"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseMove={(e) => handleMouseMove(e, index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <img
              src={art.src}
              alt={art.title}
              className={`art-image ${hoveredImage === index ? 'zoom' : ''}`}
            />
          </div>

          {/* Title */}
          <h3 className="art-title">{art.title}</h3>

          {/* Description */}
          <p
            className={`art-description ${
              hoveredDescription === index ? 'expanded' : ''
            }`}
            onMouseEnter={() => handleMouseEnterDescription(index)}
            onMouseLeave={handleMouseLeaveDescription}
          >
            {art.description || 'No description available.'}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ArtGrid;