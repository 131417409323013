const subheadingsData = {
    1: [
        {
            title: "About me",
            content: (<>
                <p>
                Hi! I'm Summer Guo, a high school student with a passion for using technology as a tool to solve 
                real-world problems and improve the human condition. 
                               
                </p>
            </>)
        },
    ],
    2: [
        {
            title: "My Interests",
            content: (<>
                <p>
                I’m deeply curious about emerging technologies and constantly looking 
                for ways to make tech more accessible, impactful, and relevant. Combining Art with Computer Science is one of my great passions. I hope to use 
                my knowledge of human behaviour and psychology with my understanding of our
                social and economic environment to create meaningful innovations or research.
                I’m always scouring for the 
                next opportunity to learn, reflect, and innovate.
                </p>
            </>)
        },
    ],
    3: [
        {
            title: "Website Details",
            content: (<>
                <p>
                I decided to build this website to create a space where I can share my past artworks, projects, poems, prose, and photos I’ve taken. The site is programmed primarily using React.js and hosted using Vercel.
                </p>
            </>)
        },
    ],
};

export default subheadingsData;